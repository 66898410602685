export const ColorMaquinaOn = '#43A047'
export const ColorMaquinaFutureOn = '#66BB6A'
export const ColorMaquinaOff = '#D50000'
export const ColorMaquinaOffLine = '#8A8A8A'
export const ColorServidorOffLine = '#120a8f'
export const ColorMaquinaFutureOff = '#EF5350'
export const ColorManuntencao = '#1976D2'
export const ColorMaquinaStopped = '#78909C'
export const ColorMaquinaSetup = '#FFA500'
export const ColorMaquinaServerOff = '#37474F'
export const ColorFerramentaAtiva = '#635e27'
export const ColorNaoInformado = '#6F00FF' 