import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")
  const _component_v_toolbar = _resolveComponent("v-toolbar")
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_container = _resolveComponent("v-container")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: $options.appCharts.showModal,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($options.appCharts.showModal) = $event)),
    transition: "dialog-bottom-transition",
    fullscreen: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, {
        fluid: "",
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                icon: "mdi-close",
                onClick: _cache[0] || (_cache[0] = $event => ($options.appCharts.showModal = false))
              }),
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("MEUS DASHBOARDS")
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_v_card, { style: {"height":"100vh"} }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, {
                justify: "start;",
                class: "p-3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        class: "d-flex flex-column justify-end align-center",
                        style: {"height":"150px"},
                        color: "primary",
                        onClick: $options.open_pareto
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            icon: "mdi-chart-bar",
                            size: "100"
                          }),
                          _createVNode(_component_v_card_title, { class: "text-white text-center" }, {
                            default: _withCtx(() => [
                              _createTextVNode("PARETO DAS PARADAS")
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["onClick"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, { cols: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        class: "d-flex flex-column justify-end align-center",
                        style: {"height":"150px"},
                        color: "primary",
                        onClick: $options.open_disponibilidade_resumida
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            icon: "mdi-chart-donut",
                            size: "100"
                          }),
                          _createVNode(_component_v_card_title, { class: "text-white text-center" }, {
                            default: _withCtx(() => [
                              _createTextVNode("DISPONIBILIDADE RESUMIDA")
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["onClick"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, { cols: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        onClick: $options.onclickStatus,
                        class: "d-flex flex-column justify-end align-center",
                        style: {"height":"150px"},
                        color: "primary"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            icon: "mdi-cog-box",
                            size: "100"
                          }),
                          _createVNode(_component_v_card_title, { class: "text-white text-center" }, {
                            default: _withCtx(() => [
                              _createTextVNode("MÁQUINA POR STATUS")
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["onClick"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, { cols: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        onClick: $options.desempenho_da_empresa,
                        class: "d-flex flex-column justify-end align-center",
                        style: {"height":"150px"},
                        color: "primary"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            icon: "mdi-finance",
                            size: "100"
                          }),
                          _createVNode(_component_v_card_title, { class: "text-white text-center" }, {
                            default: _withCtx(() => [
                              _createTextVNode("DESEMPENHO DA EMPRESA")
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["onClick"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, { cols: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        class: "d-flex flex-column justify-end align-center",
                        onClick: $options.dev_gabriel,
                        style: {"height":"150px"},
                        color: "primary"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, { class: "text-white text-center" }, {
                            default: _withCtx(() => [
                              _createTextVNode("GABRIEL DEV")
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["onClick"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, { cols: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        class: "d-flex flex-column justify-end align-center",
                        onClick: $options.dev_rebeka,
                        style: {"height":"150px"},
                        color: "primary"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, { class: "text-white text-center" }, {
                            default: _withCtx(() => [
                              _createTextVNode("REBEKA DEV")
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["onClick"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}