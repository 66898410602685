import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ml-2",
  style: {"font-weight":"bold","font-size":"16px"}
}
const _hoisted_2 = {
  class: "ml-2",
  style: {"font-weight":"bold","font-size":"16px"}
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_toolbar = _resolveComponent("v-toolbar")

  return (_openBlock(), _createBlock(_component_v_toolbar, {
    color: $options.colorStatusApontamento,
    class: "toolbar-realtime",
    height: "40",
    id: "v-toolbar_production"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, {
        justify: "space-between",
        class: "pl-0 pr-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "1",
            class: "pl-0 pr-0 pt-0 pb-0"
          }, {
            default: _withCtx(() => [
              ($options.verify_status_sensor)
                ? (_openBlock(), _createBlock(_component_v_card, {
                    key: 0,
                    color: $options.status_color,
                    style: {"width":"100%","height":"100%"},
                    class: "d-flex align-center justify-center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { size: "35" }, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-lan-connect")
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["color"]))
                : (_openBlock(), _createBlock(_component_v_card, {
                    key: 1,
                    color: '#78909C',
                    style: {"width":"100%","height":"100%"},
                    class: "d-flex align-center justify-center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { size: "35" }, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-lan-disconnect")
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }))
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_v_col, {
            cols: "11",
            class: "pl-0 pr-0"
          }, {
            default: _withCtx(() => [
              ($options.appStore.apontamento_atual.name)
                ? (_openBlock(), _createElementBlock("small", _hoisted_1, " TEMPO REAL DA MÁQUINA : " + _toDisplayString($data.tempExecucao) + " | ID : " + _toDisplayString($options.appStore.maquina_sel.id) + " | ", 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              ($options.appStore.maquina_sel && $options.appStore.maquina_sel.nome)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 1,
                    id: "btn-maquina",
                    variant: "tonal",
                    class: "ml-2",
                    onClick: $options.ShowMaquinas,
                    color: $options.appStore.apontamento_atual.tipo === 'Produção' ? '#1B5E20' : '#E53935',
                    style: {"font-weight":"bold","font-size":"16px","color":"white"},
                    height: "30"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($options.appStore.maquina_sel.nome), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick", "color"]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("small", _hoisted_2, " | " + _toDisplayString($options.appStore.maquina_sel.leitor), 1 /* TEXT */),
              ($options.appStore.apontamento_atual.op)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 2,
                    class: "ml-4",
                    style: {"font-weight":"bold","font-size":"16px","color":"white"},
                    onClick: $options.ShowDetalheOS,
                    variant: "tonal",
                    height: "30",
                    "append-icon": "mdi-folder-open"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" DESENHOS ")
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["color"]))
}