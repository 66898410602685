import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")
  const _component_v_spacer = _resolveComponent("v-spacer")
  const _component_v_toolbar = _resolveComponent("v-toolbar")
  const _component_v_text_field = _resolveComponent("v-text-field")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: $options.appMaquinas.showModal,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($options.appMaquinas.showModal) = $event)),
    "max-width": "60%",
    persistent: "",
    transition: "dialog-bottom-transition"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("MAQUINAS")
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_spacer)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    id: "filter_maquinas",
                    density: "compact",
                    modelValue: $data.filter_maquinas,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.filter_maquinas) = $event)),
                    label: "FILTRAR MAQUINAS",
                    class: "mt-2 mb-0 pl-1 pr-1 pt-0 pb-0",
                    style: {"width":"100%","font-weight":"bold"},
                    variant: "solo",
                    "bg-color": "secondary",
                    placeholder: "FILTRAR MAQUINAS"
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.resourceMaquinas, (maquina, index) => {
                return (_openBlock(), _createBlock(_component_v_row, {
                  class: "pt-0 pb-1 mt-1",
                  justify: "space-between",
                  key: index
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      class: "pt-1 pb-1 mt-1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          class: "mt-1",
                          "data-cy": `btn-machine`,
                          color: "primary",
                          height: "60",
                          onClick: $event => ($options.SelecionarMaquina(maquina)),
                          style: {"width":"100%"}
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(maquina.nome), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}