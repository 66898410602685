import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app apontamento" }
const _hoisted_2 = {
  class: "ml-1",
  style: {"font-size":"14px"}
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_navbar = _resolveComponent("navbar")
  const _component_statusMachine = _resolveComponent("statusMachine")
  const _component_v_divider = _resolveComponent("v-divider")
  const _component_detalheApontamento = _resolveComponent("detalheApontamento")
  const _component_funcionarios = _resolveComponent("funcionarios")
  const _component_ordemProducao = _resolveComponent("ordemProducao")
  const _component_apontamento = _resolveComponent("apontamento")
  const _component_observacao = _resolveComponent("observacao")
  const _component_historico = _resolveComponent("historico")
  const _component_detalhes_os = _resolveComponent("detalhes_os")
  const _component_card_recurso_por_status = _resolveComponent("card_recurso_por_status")
  const _component_maquinas = _resolveComponent("maquinas")
  const _component_disponibilidade_resumida = _resolveComponent("disponibilidade_resumida")
  const _component_gabriel = _resolveComponent("gabriel")
  const _component_rebeka = _resolveComponent("rebeka")
  const _component_view_charts = _resolveComponent("view_charts")
  const _component_motivo_de_parada = _resolveComponent("motivo_de_parada")
  const _component_motivo_parada = _resolveComponent("motivo_parada")
  const _component_desempenho_da_empresa = _resolveComponent("desempenho_da_empresa")
  const _component_load = _resolveComponent("load")
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_row = _resolveComponent("v-row")
  const _component_VSnackbar = _resolveComponent("VSnackbar")
  const _component_v_container = _resolveComponent("v-container")
  const _component_v_main = _resolveComponent("v-main")
  const _component_v_app = _resolveComponent("v-app")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_app, { style: {"background-color":"#ECEFF1"} }, {
      default: _withCtx(() => [
        _createVNode(_component_navbar),
        _createVNode(_component_v_main, { style: {"background-color":"#E0E0E0"} }, {
          default: _withCtx(() => [
            _createVNode(_component_v_container, { fluid: "" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_statusMachine),
                  _createVNode(_component_v_divider, { class: "mt-1 mb-1 pb-1 pt-1" }),
                  _createVNode(_component_detalheApontamento),
                  _createElementVNode("div", null, " Tempo de Execução: " + _toDisplayString($options.tempExecucao), 1 /* TEXT */)
                ]),
                _createVNode(_component_funcionarios),
                _createVNode(_component_ordemProducao),
                _createVNode(_component_apontamento),
                _createVNode(_component_observacao),
                _createVNode(_component_historico),
                _createVNode(_component_detalhes_os),
                _createVNode(_component_card_recurso_por_status),
                _createVNode(_component_maquinas),
                _createVNode(_component_disponibilidade_resumida),
                _createVNode(_component_gabriel),
                _createVNode(_component_rebeka),
                _createVNode(_component_view_charts),
                _createVNode(_component_motivo_de_parada),
                _createVNode(_component_motivo_parada),
                _createVNode(_component_desempenho_da_empresa),
                _createVNode(_component_load),
                _createVNode(_component_VSnackbar, {
                  timeout: 2000,
                  style: {"font-weight":"bold"},
                  modelValue: $options.appStore.message.showMessage,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($options.appStore.message.showMessage) = $event)),
                  color: $options.appStore.message.color,
                  location: "top end"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, {
                      justify: "space-between",
                      align: "center",
                      class: "d-flex align-center"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("small", _hoisted_2, _toDisplayString($options.appStore.message.message), 1 /* TEXT */),
                        _createVNode(_component_v_icon, {
                          size: "60",
                          color: "#FFEA00"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("mdi-alert-box")
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue", "color"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}