import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_HeaderApontamento = _resolveComponent("HeaderApontamento")
  const _component_v_divider = _resolveComponent("v-divider")
  const _component_gaugeDisponibilidade = _resolveComponent("gaugeDisponibilidade")
  const _component_v_col = _resolveComponent("v-col")
  const _component_eventosApontamento = _resolveComponent("eventosApontamento")
  const _component_infoprodApontamento = _resolveComponent("infoprodApontamento")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_card = _resolveComponent("v-card")

  return (_openBlock(), _createBlock(_component_v_row, { class: "pt-0 pb-0" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, { cols: "12" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, { color: "#EEEEEE" }, {
            default: _withCtx(() => [
              _createVNode(_component_HeaderApontamento),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "2" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_gaugeDisponibilidade)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, { cols: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_eventosApontamento)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, { cols: "6" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_infoprodApontamento)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}