import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"background-color":"rgb(213, 213, 213)","display":"flex","height":"calc(96vh - 64px)"} }
const _hoisted_2 = { style: {"flex":"1","padding":"10px","height":"100%"} }
const _hoisted_3 = { style: {"overflow-y":"auto","height":"100%"} }
const _hoisted_4 = { style: {"flex":"3","padding":"10px","height":"100vh"} }
const _hoisted_5 = {
  key: 0,
  style: {"height":"100%"}
}
const _hoisted_6 = {
  key: 1,
  style: {"height":"100%","display":"flex","justify-content":"center","align-items":"center"}
}
const _hoisted_7 = {
  key: 0,
  style: {"height":"100%","width":"100%"}
}
const _hoisted_8 = ["src"]
const _hoisted_9 = ["model"]
const _hoisted_10 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")
  const _component_v_spacer = _resolveComponent("v-spacer")
  const _component_v_toolbar = _resolveComponent("v-toolbar")
  const _component_v_tab = _resolveComponent("v-tab")
  const _component_v_tabs = _resolveComponent("v-tabs")
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")
  const _component_v_list_item_content = _resolveComponent("v-list-item-content")
  const _component_v_list_item = _resolveComponent("v-list-item")
  const _component_v_list = _resolveComponent("v-list")
  const _component_ejs_pdfviewer = _resolveComponent("ejs-pdfviewer")
  const _component_v_alert = _resolveComponent("v-alert")
  const _component_v_tabs_window = _resolveComponent("v-tabs-window")
  const _component_v_div = _resolveComponent("v-div")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: $options.appStore.showModalObsDetalhes,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($options.appStore.showModalObsDetalhes) = $event)),
    transition: "dialog-bottom-transition",
    fullscreen: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                onClick: _cache[0] || (_cache[0] = $event => ($options.appStore.showModalObsDetalhes = false)),
                color: "secondary",
                variant: "elevated",
                "prepend-icon": "mdi-arrow-left-circle"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("VOLTAR")
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_toolbar_title, { style: {"font-size":"16px","font-weight":"bold"} }, {
                default: _withCtx(() => [
                  _createTextVNode("DESENHOS")
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_spacer)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_v_tabs, {
            modelValue: $data.tab,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.tab) = $event)),
            "bg-color": "primary",
            color: "white",
            "slider-color": "secondary",
            "selected-class": "color-tab",
            style: {"flex-shrink":"1","min-height":"30px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_tab, {
                value: "anexos",
                style: {"width":"40%","min-width":"20%","max-width":"350px","height":"100%"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode("ANEXOS")
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"]),
          ($options.appOrdemProducao.resourceFiles.length > 0)
            ? (_openBlock(), _createBlock(_component_v_tabs_window, {
                key: 0,
                modelValue: $data.tab,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.tab) = $event)),
                style: {"height":"100%","min-height":"550px","width":"100%"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_v_list, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.appOrdemProducao.resourceFiles, (item, index) => {
                              return (_openBlock(), _createBlock(_component_v_list_item, {
                                key: index,
                                onClick: $event => ($options.ShowFile(item))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list_item_content, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(item.descricao), 1 /* TEXT */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */),
                                      _createVNode(_component_v_list_item_subtitle, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(item.arquivo), 1 /* TEXT */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */)
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1024 /* DYNAMIC_SLOTS */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]),
                          _: 1 /* STABLE */
                        })
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                      ($data.showpdf)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createVNode(_component_ejs_pdfviewer, {
                              id: "pdfViewer",
                              serviceUrl: $data.serviceUrl,
                              documentPath: $data.documentPath,
                              style: {"height":"100%","width":"100%"}
                            }, null, 8 /* PROPS */, ["serviceUrl", "documentPath"])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            ($data.documentPath)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                  ($data.isImage)
                                    ? (_openBlock(), _createElementBlock("img", {
                                        key: 0,
                                        src: $data.documentPath,
                                        alt: "Selected File",
                                        style: {"width":"100%","height":"100%","object-fit":"contain"}
                                      }, null, 8 /* PROPS */, _hoisted_8))
                                    : (_openBlock(), _createElementBlock("div", {
                                        key: 1,
                                        id: "viewer",
                                        style: {"width":"100%","height":"100%"},
                                        model: $data.documentPath,
                                        class: "online_3d_viewer border-bottom border-left border-right"
                                      }, null, 8 /* PROPS */, _hoisted_9))
                                ]))
                              : ($data.showError)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                    _createVNode(_component_v_alert, {
                                      color: "error",
                                      icon: "$error",
                                      title: "Erro:",
                                      text: "Não foi possível abrir o arquivo de imagem. Verifique se o formato é suportado e tente novamente."
                                    })
                                  ]))
                                : _createCommentVNode("v-if", true)
                          ]))
                    ])
                  ])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue"]))
            : (_openBlock(), _createBlock(_component_v_div, {
                key: 1,
                style: {"width":"100%"},
                class: "mt-4 pl-2 pr-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_alert, {
                    text: "Nenhum desenho encontrado para a OS selecionada.",
                    type: "warning",
                    color: "secondary"
                  })
                ]),
                _: 1 /* STABLE */
              }))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}