<template>
  <div style="display: flex; flex-direction: column; align-items: center;">
    <h5 class="mb-1">{{appStore.apontamento_atual.turno}}</h5>
    <div id="gauge_render" class="gauge"></div>
    <h5 class="texto-disponibilidade mt-4">Disponibilidade de Hoje</h5>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { useAppStore } from "../../stores/app";

export default {
  name: "gaugeDisponibilidade",
  props: {
    value_gauge: {
      type: Number,
      default: 0
    }
  },
  methods: {
    render_gauge() {
      var chartDom = document.getElementById('gauge_render');
      var myChart = echarts.init(chartDom);
      var option;

      var radiusValue = "0%";
      var widthSplitLine = 0;


      if (window.innerWidth <= 1280) {
        radiusValue = "100%";
        widthSplitLine = 0;
      } else {
        radiusValue = "140%";
        widthSplitLine = 4;
      }


      option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            center: ['50%', '90%'],
            radius: radiusValue,
            min: 0,
            max: 100,
            splitNumber: 4,
            axisLine: {
              lineStyle: {
                width: 10,
                color: [
                  [0.25, '#D50000'],
                  [0.5, '#FDD600'],
                  [0.75, '#1976D2'],
                  [1, '#43A047']
                ]
              }
            },
            pointer: {
              icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
              length: '80%',
              width: 10,
              offsetCenter: [0, '-50%'],
              itemStyle: {
                color: 'black'
              }
            },
            axisTick: {
              length: 2,
              lineStyle: {
                color: 'auto',
                width: 0
              }
            },
            splitLine: {
              length: 10,
              lineStyle: {
                color: 'auto',
                width: widthSplitLine
              }
            },
            axisLabel: {
              color: '#000',
              fontSize: 10,
              distance: -30,
              rotate: 'tangential',
              formatter: function (value) {
                if (value === 0) {
                  return '0';
                } else if (value === 25) {
                  return '25';
                } else if (value === 50) {
                  return '50';
                } else if (value === 75) {
                  return '75';
                } else if (value === 100) {
                  return '100';
                }
                return '';
              }
            },
            title: {
              offsetCenter: [0, '5%'],
              fontSize: 15,
              fontStyle: "Bold"
            },
            detail: {
              fontSize: 15,
              offsetCenter: [0, '0%'],
              valueAnimation: true,
              formatter: function (value) {
                return (value) + '%';
              },
              color: 'black'
            },
            data: [
              {
                value: useAppStore().maquina_sel.disponibilidade_diaria,
                name: '',
              }
            ]
          }
        ],

      };

      option && myChart.setOption(option);

      setInterval(() => {
        myChart.setOption({
          series: [{
            data: [{
              value: useAppStore().maquina_sel.disponibilidade_diaria,
              name: '',
            }]
          }]
        });
      }, 5000);
    }
  },
  mounted() {
    this.render_gauge();
  },
  computed: {
    appStore() {
      return useAppStore();
    },
  },
}


</script>

<style scoped>
#div-gauge-child {
  align-items: center;
}

.gauge {
  min-height: 100px;
  min-width: 200px;
  display: flex;
  align-items: flex-start;
}

@media (max-width: 1280px) {
  .gauge {
    min-width: 130px;
  }
}
</style>