<template>
  <v-row class="pt-0 pb-0">
    <v-col cols="12">
      <v-card color="#EEEEEE">
        <HeaderApontamento/>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="2">
            <gaugeDisponibilidade />
          </v-col>
          <v-col cols="4">
            <eventosApontamento />
          </v-col>
          <v-col cols="6">
            <infoprodApontamento />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import HeaderApontamento from './headerApontamento.vue';

import gaugeDisponibilidade from './gaugeDisponibilidade.vue';

import eventosApontamento from './eventosApontamento.vue';

import infoprodApontamento from './infoprodApontamento.vue';

export default {
  name: "statusMachine",
  components: {
    HeaderApontamento,
    gaugeDisponibilidade,
    eventosApontamento,
    infoprodApontamento
  },
  data() {
    return {
      tempExecucao: '00:00:00'
    };
  },
  computed: {
    appMachine() {
      return useAppMachine()
    }
  },
  mounted() {


  },
  methods: {

  }
};
</script>

<style scoped></style>