<template>
  <div>
    <v-app style="background-color: #ECEFF1">
      <navbar />
      <v-main style="background-color: #E0E0E0">
        <v-container fluid >
          <div class="app apontamento">
            <statusMachine />
            <v-divider class="mt-1 mb-1 pb-1 pt-1"></v-divider>
            <detalheApontamento />
            <div>
              Tempo de Execução: {{ tempExecucao }}
            </div>
          </div>
          <funcionarios />
          <ordemProducao />
          <apontamento />
          <observacao />
          <historico />
          <detalhes_os />
          <card_recurso_por_status />
          <maquinas />
          <disponibilidade_resumida/>
          <gabriel/>
          <rebeka/>
          <view_charts />
          <motivo_de_parada/>
          <motivo_parada/>
          <desempenho_da_empresa/>
          <load />
          <VSnackbar :timeout="2000" style="font-weight: bold;" v-model="appStore.message.showMessage"
            :color="appStore.message.color" location="top end">
            <v-row justify="space-between" align="center" class="d-flex align-center">
              <small class="ml-1" style="font-size: 14px;">{{ appStore.message.message }}</small>
              <v-icon size="60" color="#FFEA00">mdi-alert-box</v-icon>
            </v-row>
          </VSnackbar>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>

import load from './app/producao/modals/load.vue'
import navbar from './app/navbar.vue';
import statusMachine from './app/producao/statusMachine.vue';
import detalheApontamento from './app/producao/detalheApontamento.vue';
import funcionarios from './app/producao/modals/funcionarios.vue';
import ordemProducao from './app/producao/modals/ordemProducao.vue';
import apontamento from './app/producao/modals/apontamento.vue';
import observacao from './app/producao/modals/observacao.vue';
import historico from './app/producao/modals/historico.vue';
import detalhes_os from './app/producao/modals/detalhes_os.vue';
import maquinas from './app/producao/modals/maquinas.vue';
import disponibilidade_resumida from './app/producao/charts/disponibilidade_resumida.vue';
import view_charts from './app/producao/charts/view.vue';
import gabriel from './app/producao/modals/gabriel.vue'
import motivo_de_parada from './app/producao/charts/motivo_de_parada.vue';
import motivo_parada from './app/producao/modals/motivos_parada.vue';
import desempenho_da_empresa from './app/producao/charts/desempenho_da_empresa.vue';

import card_recurso_por_status from './app/producao/charts/recursos_por_status.vue';

import { useAppStore } from './stores/app';
import { useAppFuncionarios } from './stores/appFuncionarios';
import { useAppMaquinas } from './stores/appMaquinas';
import { useAppOrdemProducao } from './stores/appOrdemProducao';
import { useAppCharts } from './stores/appCharts';
import Rebeka from './app/producao/modals/rebeka.vue';


export default {
  name: 'App',
  components: {
    navbar,
    statusMachine,
    detalheApontamento,
    funcionarios,
    ordemProducao,
    apontamento,
    observacao,
    historico,
    detalhes_os,
    card_recurso_por_status,
    view_charts,
    maquinas,
    disponibilidade_resumida,
    gabriel,
    motivo_de_parada,
    desempenho_da_empresa,
    Rebeka,
    motivo_parada,
    load
  },


  data() {
    return {
      count: 0
    }
  },
  computed: {
    appStore() {
      return useAppStore();
    },
    chartsStore() {
      return useAppCharts();
    },
    funcionariosStore() {
      return useAppFuncionarios()
    },
    maquinasStore() {
      return useAppMaquinas()
    },
    opsStore() {
      return useAppOrdemProducao()
    },
    tempExecucao() {
      return this.chartsStore.tempExecucao;
    },
  },
  mounted() {
    frappe.realtime.on('recurso', (data) => {
      this.maquinasStore.update_recurso_realtime(data)

    })

    frappe.realtime.on('apontamento_app', (data) => {
      // if (Object.keys(data).includes('operador')) if (data.operador) this.funcionariosStore.update_apontamento_realtime(data);
      if (Object.keys(data).includes('recurso')) this.maquinasStore.update_apontamento_realtime(data);
      // (Object.keys(data).includes('ordem_de_producao')) if (data.ordem_de_producao) this.opsStore.update_apontamento_realtime(data);
      // this.chartsStore.update_apontamento_realtime(data)
    })

    frappe.realtime.on('disponibilidade_por_turno', (data) => {
      this.appStore.set_gauge(Number(data.toFixed(2)))
    })


  },
}

</script>

<style>
@import "../../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css";
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap5.css";
@import '../../node_modules/@syncfusion/ej2-lists/styles/bootstrap5.css';
@import "../../node_modules/@syncfusion/ej2-vue-calendars/styles/bootstrap5.css";
@import '../../node_modules/@syncfusion/ej2-vue-pdfviewer/styles/bootstrap5.css';
@import "../../node_modules/@syncfusion/ej2-vue-pivotview/styles/bootstrap5.css";

.e-grid .e-rowcell {
  /* color: white !important; */
  color: black !important;
  font-weight: bold;
  font-size: 14px !important;
}

.e-grid .e-headercell {
  background-color: #121935 !important;
  /* Cor de fundo desejada */
  color: black !important;
  /* Cor do texto */
}

.e-headertext {
  color: #ffffff !important;
  /* Cor do texto */
}

.e-grid .e-sortfilterdiv {
  font-weight: bold;
  color: #00acc1 !important;
}

.color-tab {
  background-color: #90A4AE !important;
  color: rgb(255, 255, 255);
}

.v-container{
  padding: 0 !important;
}
</style>